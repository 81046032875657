import moment from 'moment';
/**
 * 主要用于今日，本周，本月，本年
 */
export function AllTimeRange () {
    let date = new Date();
    let year = date.getFullYear() + '',
        month = date.getMonth() + 1 + '',
        day = date.getDate() + '';
    let curDate = `${year}${month.length === 1 ? `0${month}` : month}${day.length === 1 ? `0${day}` : day}`,
        curMonth = `${year}${month.length === 1 ? `0${month}` : month}01`,
        curYear = `${year}0101`;
    return {
        RangeDay:[moment(curDate), moment(new Date().getTime())],
        RangeWeek:[moment(moment().day(0).format('YYYY/MM/DD')), moment(new Date().getTime())],// 需求要求(DTS001519560)是从周日开始为本周第一天，所以weekday(0)改为day(0)
        RangeMonth:[moment(curMonth), moment(new Date().getTime())],
        RangeYear:[moment(curYear), moment(new Date().getTime())]
    };
}